import { useSelector } from 'react-redux';
import { createAction, createReducer } from 'redux-act';

import type { IMicrocreditRegion } from '@src/@types/clienttypes';
import { Currency } from '@src/@types/currency';
import type * as Microcredits from '@src/@types/microcredits';
import { FILTERS_DICTIONARIES_KEYS } from '@src/constants/dictionaries';
import { DEFAULT_FILTERS, DEFAULT_REGION_ROUTE, MODES } from '@src/constants/filters';
import { ListKeys } from '@src/constants/microcredits';
import { getOffersWithAdvertising } from '@src/helpers/advertising';
import { getBenefitsNamesByValues } from '@src/helpers/dictionaries';
import { loadGuruleadsList } from '@src/services/guruleads';
import { loadLeadsSuList } from '@src/services/leadssu';
import { loadMicroCreditsRegions } from '@src/services/locations';
import { loadCalculatorList, loadLandingList, loadList, loadPopupList } from '@src/services/microcredits';
import type { ReduxAction } from '@src/store/types';

import { getDictionaryFieldsByAlias } from './dictionaries';
import type { ILocation } from './locations';

import type { IGlobalState } from '.';

const loadByKey = {
    [ListKeys.LIST]: loadList,
    [ListKeys.PARTNERS]: loadList,
    [ListKeys.LANDING]: loadList,
    [ListKeys.BRANDING]: loadLandingList,
    [ListKeys.LEADSSU]: loadLeadsSuList,
    [ListKeys.GURULEADS]: loadGuruleadsList,
    [ListKeys.PROMOTION]: loadList,
    [ListKeys.ONLINE]: loadList,
    [ListKeys.CALCULATOR]: loadCalculatorList,
    [ListKeys.BESTPRODUCTS]: loadList,
    [ListKeys.BESTOFFERS]: loadPopupList,
    [ListKeys.NOVINKI]: loadList,
    [ListKeys.MOBILE_POPUP]: loadPopupList,
    [ListKeys.WL_PAGE]: loadList,
    [ListKeys.WL_DENIED_PAGE]: loadList,
    [ListKeys.SIDEBAR_BANNER]: loadList,
    [ListKeys.SPEC_OFFERS]: loadList,
    [ListKeys.ZAIMY_POD_PTS]: loadList,
    [ListKeys.KAZAKHSTAN]: loadList,
    [ListKeys.NEW_SPECIAL]: loadList,
    [ListKeys.OCTOBER_PRIZE_DRAW]: loadList,
};

const DEFAULT_LIST_KEY = ListKeys.LIST;

interface IState {
    lists?: Record<ListKeys.LIST | string, Microcredits.List.IStoreData>;
    isExtraFiltersVisible: boolean;
    filters?: Microcredits.IFilters;
    regions: {
        items: IMicrocreditRegion[];
    };
    metadata?: Microcredits.List.IMetadata;
}

const initialState: IState = {
    lists: {},
    isExtraFiltersVisible: false,
    filters: {
        location: '6.83.',
        currency: Currency.RUB,
        limit: 20,
    },
    regions: {
        items: [],
    },
};

const setMicroCreditsList = createAction<{ key: string; data: Partial<Microcredits.List.IStoreData> }>(
    'credits/list/set',
);
export const setMicroCredits = createAction<{
    lists: Record<string, Microcredits.List.IStoreData>;
    metadata?: Microcredits.List.IMetadata;
}>('credits/all/set');

const setMicroCreditsMetadata = createAction<IState['metadata']>('credits/metadata/set');

const setRegions = createAction<IState['regions']>('credits/regions/set');

export const setExtraFiltersVisibility = createAction<IState['isExtraFiltersVisible']>(
    'credits/extra/filters/visibility/set',
);

export const microcreditsReducer = createReducer<IState>({}, initialState)
    .on(setExtraFiltersVisibility, (state, payload) => ({ ...state, isExtraFiltersVisible: payload }))
    .on(setMicroCreditsMetadata, (state, payload) => ({ ...state, metadata: payload }))
    .on(setRegions, (state, payload) => ({ ...state, regions: payload }))
    .on(setMicroCreditsList, (state, payload) => {
        const { key, data } = payload;
        const { lists } = state;

        if (!lists) {
            return {
                ...state,
                lists: { [key]: data },
            };
        }

        return {
            ...state,
            lists: { ...lists, [key]: { ...lists[key], ...data } },
        };
    })
    .on(setMicroCredits, (state, { lists, metadata }) => ({
        ...state,
        lists,
        metadata,
    }));

interface IFetchOfferParams {
    key: ListKeys;
    query?: Microcredits.List.IReq;
    params?: Microcredits.List.IReq;
    preloadedLimit?: number;
}

export function fetchOffers({ key, query = {}, params = {}, preloadedLimit }: IFetchOfferParams): ReduxAction {
    return async (dispatch, getState) => {
        // eslint-disable-next-line @typescript-eslint/init-declarations
        let benefits;
        dispatch(
            setMicroCreditsList({
                key,
                data: {
                    items: [],
                    count: 0,
                    query,
                    params,
                    loading: true,
                    error: false,
                    loadingMore: false,
                },
            }),
        );

        if (query.benefits) {
            const benefitsFields =
                getDictionaryFieldsByAlias(getState().dictionaries, FILTERS_DICTIONARIES_KEYS.BENEFITS) || [];
            benefits = getBenefitsNamesByValues(benefitsFields, query.benefits);
        }

        try {
            const request = loadByKey[key]({
                ...query,
                ...params,
                limit: preloadedLimit ?? query.limit,
                benefits,
                withBonus: key === ListKeys.PROMOTION || undefined,
            }) as Promise<Microcredits.List.IRes>;

            const { items: rawItems, total: rawTotal, count: rawCount, metadata } = await request;

            const { items } = getOffersWithAdvertising(rawItems);
            const total = items.length && rawTotal;
            const count = items.length && rawCount;

            dispatch(
                setMicroCreditsList({
                    key,
                    data: {
                        items,
                        total,
                        count,
                        query,
                        params,
                        loading: false,
                        error: false,
                    },
                }),
            );

            if (metadata) {
                metadata.counts.offers = count;
                dispatch(setMicroCreditsMetadata(metadata));
            }
        } catch (error) {
            console.error('FETCH MICROCREDITS ERROR: ', error.message);
            dispatch(setMicroCreditsList({ key, data: { error: true, loading: false } }));
        }
    };
}

export const getCreditsLists = (state: IGlobalState) => state.credits.lists || {};
const getCreditsList = (state: IGlobalState, key: ListKeys = DEFAULT_LIST_KEY) => {
    if (!state.credits.lists) return {};

    return state.credits.lists[key] ?? {};
};

type FetchMoreOffersParams = {
    key: ListKeys;
    init?: boolean;
};
export function fetchMoreOffersAB({ key = DEFAULT_LIST_KEY, init = false }: FetchMoreOffersParams): ReduxAction {
    return async (dispatch, getState) => {
        const state = getState();
        const { count = 0, items: loadedItems, loading, loadingMore, query, params } = getCreditsList(state, key);

        if (Array.isArray(loadedItems) && loadedItems.length < count && !loading && !loadingMore) {
            dispatch(
                setMicroCreditsList({
                    key,
                    data: {
                        loadingMore: true,
                        errorLoadingMore: false,
                    },
                }),
            );

            try {
                const request = loadByKey[key]({
                    ...query,
                    ...params,
                    ...(init && query?.limit && { limit: query.limit - loadedItems.length }),
                    skip: loadedItems.length,
                    withBonus: key === ListKeys.PROMOTION || undefined,
                }) as Promise<Microcredits.List.IRes>;

                const { items: rawItems, total } = await request;

                const { items } = getOffersWithAdvertising([...loadedItems, ...rawItems]);

                dispatch(
                    setMicroCreditsList({
                        key,
                        data: {
                            items,
                            total,
                            count,
                            query,
                            params,
                            loadingMore: false,
                            errorLoadingMore: false,
                        },
                    }),
                );
            } catch (error) {
                console.error('FETCH MORE MICROCREDITS ERROR: ', error.message);
                dispatch(setMicroCreditsList({ key, data: { errorLoadingMore: true, loadingMore: false } }));
            }
        }
    };
}

type FetchBestOffers = {
    location: ILocation;
};

export function fetchBestOffers({ location }: FetchBestOffers): ReduxAction {
    return fetchOffers({
        key: ListKeys.BESTPRODUCTS,
        query: DEFAULT_FILTERS[MODES.BESTPRODUCTS],
        params: {
            location: location?.route || DEFAULT_REGION_ROUTE,
        },
    });
}

export const fetchMicroCreditsRegions = (seoActiveCitiesUrl?: string): ReduxAction => async (dispatch) => {
    try {
        const { items } = await loadMicroCreditsRegions({ adsOnly: true, seoActiveCitiesUrl });
        dispatch(setRegions({ items }));
    } catch (error) {
        dispatch(setRegions({ items: [] }));
    }
};

// LIST GETTERS
export const getCreditsMetadata = (state: IGlobalState) => state.credits.metadata;

const DEFAULT_LIST_QUERY = {};
const getCreditsFilters = (state: IGlobalState, key: ListKeys) => {
    const list = getCreditsList(state, key);

    return list.query || DEFAULT_LIST_QUERY;
};
const getMicroCreditsRegions = (state: IGlobalState) => state.credits.regions;
const getCreditsExtraFiltersVisibility = (state: IGlobalState) => state.credits.isExtraFiltersVisible;
// LIST SELECTORS
export function useSelectCreditsExternalFiltersVisibility(): IState['isExtraFiltersVisible'] {
    return useSelector<IGlobalState, IState['isExtraFiltersVisible']>(getCreditsExtraFiltersVisibility);
}
export function useSelectCreditsList(key: ListKeys) {
    return useSelector((state: IGlobalState) => getCreditsList(state, key));
}
export function useSelectCreditsFilters(key: ListKeys): IState['filters'] {
    return useSelector((state: IGlobalState) => getCreditsFilters(state, key));
}
export function useSelectMicroCreditsRegions(): IState['regions'] {
    return useSelector<IGlobalState, IState['regions']>(getMicroCreditsRegions);
}
